.help-page .row {
  align-items: baseline;
}

.faq-div {
  box-sizing: border-box;
  color: rgb(243, 243, 243);
  font-family: Inter, Arial;
  letter-spacing: -0.08px;
  pointer-events: auto;
  position: relative;
  text-align: left;
  text-rendering: optimizelegibility;
  text-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px;
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-in-out;
  margin: 2px;
  border-radius: 8px;
  background-color: #04254e;
}

.faq-div:hover {
  background-color: #5954A4;
}

.faq-collapsed {
  background-color: #5954A4;
}

.faq-uncollapsed {
  background-color: #04254e;
}

.faq-question-div {
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 30px;
  font-size: 18px;
}

.faq-question-div:hover {
  cursor: pointer;
}

.faq-question-icon {
  font-size: 20px;
  font-weight: bolder;
}

.faq-answer {
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 70px;
}

hr.long-dash {
  border-top: solid 2px #fff;
  width: 10px;
  margin: 10px auto;
}

.view-guide-button {
  margin-top: 20px;
}

iframe[title="User Guide"] {
  width: 100%;
  height: 600px;
  border: none;
}

.icon-button-collapse {
  display: flex;
  margin-top: auto;
  justify-content: flex-end;
  margin: 20px;
}

.svg-container svg {
  transition: background-color 0.3s;
  border-radius: 5px;
  stroke: #5954a4;
}

.svg-container svg:hover {
  background-color: #5954a4;
  stroke: #fff;
  cursor: pointer;
}